import styles from './styles.module.css';
import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CarouselArrow } from 'src/icons/carouselArrow';

interface ArrowProps {
	direction: 'left' | 'right';
	onClick?: () => any;
	style?: CSSProperties;
}

const CustomArrow = (props: ArrowProps) => {
	const { onClick, direction, style } = props;
	const buttonClass = classNames([
		{ [styles.rightButton]: direction === 'right' },
		{ [styles.leftButton]: direction === 'left' },
	]);
	return (
		<div className={buttonClass} onClick={() => (onClick ? onClick() : undefined)}>
			<CarouselArrow direction={direction} style={style} />
		</div>
	);
};

interface Props {
	children: ReactNode;
	itemsShown?: {
		largeScreen: number;
		mediumScreen: number;
		smallScreen: number;
	};
	className?: string;
}

export const MultiCarousel = (props: Props) => {
	const { children, itemsShown: propsItemsShown, className } = props;
	// breakpoints taken from /src/styles/shared.module.css
	const itemsShown = propsItemsShown
		? propsItemsShown
		: {
				largeScreen: 4,
				mediumScreen: 3,
				smallScreen: 2,
		  };
	const responsive = {
		large: {
			breakpoint: { max: 10000, min: 960 },
			items: itemsShown.largeScreen,
		},
		medium: {
			breakpoint: { max: 960, min: 600 },
			items: itemsShown.mediumScreen,
		},
		small: {
			breakpoint: { max: 600, min: 0 },
			items: itemsShown.smallScreen,
		},
	};

	const leftArrow = (
		<div className={styles.leftArrow}>
			<CustomArrow direction="left" style={{ paddingLeft: '0', marginLeft: '-4px' }} />
		</div>
	);
	const rightArrow = (
		<div className={styles.rightArrow}>
			<CustomArrow direction="right" style={{ paddingLeft: '0' }} />
		</div>
	);

	return (
		<Carousel
			responsive={responsive}
			containerClass={classNames(styles.container, className)}
			itemClass={styles.item}
			draggable
			swipeable
			arrows
			customLeftArrow={leftArrow}
			customRightArrow={rightArrow}
			minimumTouchDrag={80}
			keyBoardControl
			additionalTransfrom={0}
			slidesToSlide={1}
			partialVisible
		>
			{children}
		</Carousel>
	);
};
